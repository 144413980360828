import type { PaginationData } from '../base'
import http from '../client'
import type {
  CreateProjectCommand,
  ListProjectByPageVO,
  ListProjectMemberVo,
  MoveProjectCommand,
  PageFormValueVO,
  ProjectAttributeVO,
  ProjectDTO,
  ProjectPageAttributeDTO,
  ProjectPageTreeQuery,
  ProjectPageTreeVO,
  ProjectPaginationCommand,
  ProjectStructureAttributeVO,
  ProjectStructureTemplateCommand,
  ProjectStructureTemplateInfoVO,
  ProjectStructureTemplateQuery,
  ProjectStructureTypeQuery,
  ProjectStructureVO,
  StructureIdentifierOverviewVO,
  TransferProjectOwnerCommand,
  UpdateProjectCommand,
  UpdateProjectMemberCommand,
  UpdateProjectStructureCommand,
} from './project.d'
import type { CadProjectShortVo } from './space.d'
import type { StructureTemplateBriefVO } from './structure.d'

export function createProject(data: CreateProjectCommand) {
  return http<{ id: number }>({
    url: '/cad/api/project/create-project',
    data,
  })
}

export async function queryProject(id: number) {
  const data = await http<ProjectDTO>({
    url: '/cad/api/project/query-project',
    data: { id },
  })
  const [createdDate, createdTime2] = data.createdTime!.split(' ')
  return {
    ...data,
    createdDate,
    createdTime2,
  } as ProjectDTO
}

export function deleteProject(id: number) {
  return http({
    url: '/cad/api/project/delete-project',
    data: { id },
  })
}

export function deletePersonalProject(id: number) {
  return http({
    url: '/cad/api/project/delete-personal-project',
    data: { id },
  })
}

export function updateProject(data: UpdateProjectCommand) {
  return http({
    url: '/cad/api/project/update-project',
    data,
  })
}

export function updatePersonalProject(data: UpdateProjectCommand) {
  return http({
    url: '/cad/api/project/update-personal-project',
    data,
  })
}

export function copyPersonalProject(data: { name: string; projectId: number }) {
  return http({
    url: '/cad/api/project/copy-personal-project',
    data,
  })
}

export function renameProject(data: { id: number; name: string }) {
  return http({
    url: '/cad/api/project/rename',
    data,
  })
}

export function copyProject(data: { projectId: number; name: string }) {
  return http<ProjectDTO>({
    url: '/cad/api/project/copy-project',
    data,
  })
}

export function moveProject(data: MoveProjectCommand) {
  return http({
    url: '/cad/api/project/move-project',
    data,
  })
}

export function movePersonalProject(data: MoveProjectCommand) {
  return http({
    url: '/cad/api/project/move-personal-project',
    data,
  })
}

export function listProjectMember(id: number) {
  return http<ListProjectMemberVo[]>({
    url: '/cad/api/project/list-project-member',
    data: { id },
  })
}

export function updateProjectMember(data: UpdateProjectMemberCommand[]) {
  return http({
    url: '/cad/api/project/update-project-member',
    data,
  })
}

export function listProjectPageTree(projectIds: number[]) {
  return http<ProjectPageTreeVO[]>({
    url: '/cad/api/project/list-project-page-tree',
    data: { projectIds },
  })
}

export function listProjectStructure(id: number) {
  return http<ProjectStructureVO[]>({
    url: '/cad/api/project/list-project-structure',
    data: { id },
  })
}

export function updateProjectStructure(data: UpdateProjectStructureCommand[]) {
  return http<ProjectStructureVO[]>({
    url: '/cad/api/project/update-project-structure',
    data,
  })
}

export function listProjectSelectedAttribute(id: number) {
  return http<ProjectAttributeVO[]>({
    url: '/cad/api/project/list-project-selected-attribute',
    data: { id },
  })
}

export function listProjectSelectedAttributes(ids: number[]) {
  return Promise.all(ids.map(listProjectSelectedAttribute))
}

export function updateProjectAttributeRelation(data: {
  projectId: number
  attributeIds: number[]
}) {
  return http({
    url: '/cad/api/project/update-project-attribute-relation',
    data,
  })
}

export function updateProjectAttribute(data: { value: string; projectAttributeId: number }) {
  return http({
    url: '/cad/api/project/update-project-attribute',
    data,
  })
}

export function listStructureTemplateBrief(data: { type: string; projectId: number }) {
  return http<StructureTemplateBriefVO[]>({
    url: '/cad/api/project/list-structure-template-brief',
    data,
  })
}

export function queryProjectStructureTemplateInfo(data: ProjectStructureTemplateQuery) {
  return http<ProjectStructureTemplateInfoVO>({
    url: '/cad/api/project/query-project-structure-template-info',
    data,
  })
}

export function addProjectStructureTemplate(data: ProjectStructureTemplateCommand) {
  return http<ProjectStructureTemplateInfoVO>({
    url: '/cad/api/project/add-project-structure-template',
    data,
  })
}

export function updateProjectStructureTemplate(data: ProjectStructureTemplateCommand) {
  return http<ProjectStructureTemplateInfoVO>({
    url: '/cad/api/project/update-project-structure-template',
    data,
  })
}

export function closeProject(id: number) {
  return http<ProjectStructureTemplateInfoVO>({
    url: '/cad/api/project/close-project',
    data: { id },
  })
}

export function deleteProjectStructureTemplate(id: number) {
  return http({
    url: '/cad/api/project/delete-project-structure-template',
    data: { id },
  })
}

export function listProjectByPage(data: ProjectPaginationCommand) {
  return http<PaginationData<ListProjectByPageVO>>({
    url: '/cad/api/project/list-project-by-page',
    data,
  })
}

export function listProjectStructureAttributeByType(data: ProjectStructureTypeQuery) {
  if (!data.availableTypes) {
    data.availableTypes = ['1', '2']
  }
  return http<ProjectStructureAttributeVO[]>({
    url: '/cad/api/project/list-project-structure-attribute-by-type',
    data,
  })
}

export async function listRecentlyUsedProject() {
  const res = await http<PaginationData<CadProjectShortVo>>({
    url: '/cad/api/project/list-recently-used-project-by-page',
    data: {
      pageIndex: 1,
      pageSize: 100,
    },
  })
  return res.data
}

export async function listFavoriteProject() {
  const res = await http<PaginationData<CadProjectShortVo>>({
    url: '/cad/api/project/list-favorite-project-by-page',
    data: {
      pageIndex: 1,
      pageSize: 100,
    },
  })
  return res.data
}

export function addProjectFavorite(projectId: number) {
  return http({
    url: '/cad/api/project/add-project-favorite',
    data: { projectId },
  })
}

export function deleteProjectFavorite(projectId: number) {
  return http({
    url: '/cad/api/project/delete-project-favorite',
    data: { projectId },
  })
}

export function changeProjectFavorite(projectId: number, favorite: boolean) {
  return favorite ? addProjectFavorite(projectId) : deleteProjectFavorite(projectId)
}

export function createPersonalProject(data: CreateProjectCommand) {
  return http({
    url: '/cad/api/project/create-personal-project',
    data,
  })
}

export function transferProjectOwner(data: TransferProjectOwnerCommand) {
  return http({
    url: '/cad/api/project/transfer-project-owner',
    data,
  })
}

export function listProjectForm(data: { formId: number; projectId: number }, filterCmd: any) {
  return http<PageFormValueVO[]>({
    url: '/cad/api/project/list-project-form',
    data: { ...data, ...filterCmd },
  })
}

export async function listStructureIdentifierOverview(id: number, filterCmd: any) {
  const res = await http<StructureIdentifierOverviewVO[]>({
    url: '/cad/api/project/list-structure-identifier-overview',
    data: { id, ...filterCmd },
  })
  return res
}

export async function listMarkLetter(id: number) {
  const res = await http<string[]>({
    url: '/cad/api/project/list-mark-letter',
    data: { id },
  })
  return res
}

export function listFeatureCategoryName(id: number) {
  return http<string[]>({
    url: '/cad/api/project/list-feature-category-name',
    data: { id },
  })
}

export function listDeviceIdentifier(id: number) {
  return http<string[]>({
    url: '/cad/api/project/list-device-identifier',
    data: { id },
  })
}

export function listMountingPlate(id: number) {
  return http<any[]>({
    url: '/cad/api/project/list-mounting-plate',
    data: { id },
  })
}

export function listProjectPageAttribute(id: number) {
  return http<ProjectPageAttributeDTO[]>({
    url: '/cad/api/project/list-project-page-attribute',
    data: { id },
  })
}

export function coverProjectPageAttribute(projectId: number, attributeCodes: number[]) {
  return http<ProjectAttributeVO[]>({
    url: '/cad/api/project/cover-project-page-attribute',
    data: { projectId, attributeCodes },
  })
}
