import http from './client'
import type {
  ResponseData,
  LoginData,
  RegisterData,
  EmployeePageQuery,
  Department,
  DepartmentResponse,
  CreateDepartmentFormData,
  UpdateDepartmentFormData,
  WeChatResponse,
  selfInfo,
  QueryUserEmployee,
  Organization,
  MemberPermissionVO,
  MemberPermissionCommand,
  UpdateStaffRoleListFormData,
  Role,
  UpdateEmployeeCommand,
  OrganizationVO,
  EmployeeVo,
} from './ram.d'

export interface PaginationQuery {
  pageIndex: number
  pageSize?: number
}

export interface PaginationResData<T> extends PaginationQuery {
  totalCount: number
  totalPage: number
  data: T
}

export const weChatQueryConfig = () =>
  http<{
    // 应用唯一标识，在微信开放平台提交应用审核通过后获得
    appid: string
    // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
    scope: string
    // 重定向地址，需要进行UrlEncode
    redirect_uri: string
    // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
    state?: string
    // 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
    theme?: string
    // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
    href?: string
    // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
    self_redirect?: boolean
    style?: string
  }>({
    url: '/ram/api/wechat/query-config',
  })

export const userLogin = (data: LoginData) => {
  return http<ResponseData>({
    url: '/ram/api/user/login',
    data,
  })
}

export const userRegister = (data: RegisterData) => {
  return http<ResponseData>({
    url: '/ram/api/user/register',
    data,
  })
}

export const verificationCode = (data: { phone: string }) => {
  return http({
    url: '/ram/api/sms/send-verification-code',
    data,
  })
}

export const generateInvitedToken = () => {
  return http({
    url: '/ram/api/employee/generate-invited-token',
  })
}

export const getMemberList = (data: any) => {
  return http<PaginationResData<EmployeePageQuery[]>>({
    url: '/ram/api/employee/list-employee-by-page',
    data,
  })
}

export const updateEmployee = (data: UpdateEmployeeCommand) => {
  return http({
    url: '/ram/api/employee/update-employee',
    data,
  })
}

export const getDepartmentTreeData = (data?: {
  departmentName?: string
  containEmployee?: boolean
}) => {
  return http<DepartmentResponse>({
    url: '/ram/api/department/list-department-tree',
    data: data || {},
  })
}

export const createDepartment = (data: CreateDepartmentFormData) => {
  return http<Department>({ url: '/ram/api/department/create-department', data })
}

export const updateDepartment = (data: UpdateDepartmentFormData) => {
  return http<Department>({ url: '/ram/api/department/update-department', data })
}

export const deleteDepartment = (data: { id: number }) => {
  return http({ url: '/ram/api/department/delete-department', data })
}

export const updateDepartmentOrder = (data: {
  id: number
  parentId: number | undefined
  sequence: number
}) => {
  return http({ url: '/ram/api/department/update-department-sequence', data })
}

export const querySelfInfo = () => {
  return http<selfInfo>({ url: '/ram/api/account/query-account' })
}
export const bindEmail = (data: { email: string }) => {
  return http({ url: '/ram/api/account/bind-email', data })
}
export const updatePhoneInfo = (data: { phone: string; verificationCode: string }) => {
  return http({ url: '/ram/api/account/bind-phone', data })
}
export const updatePassword = (data: {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}) => {
  return http({
    url: '/ram/api/account/update-password',
    data,
  })
}
export const updateNameInfo = (data: { name: string }) => {
  return http({ url: '/ram/api/account/update-name', data })
}
export const updateGenderInfo = (data: { gender: string }) => {
  return http({ url: '/ram/api/account/update-gender', data })
}

export const updateAvatar = (avatar: string) => {
  return http({ url: '/ram/api/account/update-avatar', data: { avatar } })
}
export const updateCart = (card: string) => {
  return http({ url: '/ram/api/account/update-card', data: { card } })
}
export const unbindWeChat = () => {
  return http({ url: '/ram/api/account/unbind-wechat' })
}

export const queryBindConfig = () => {
  return http<WeChatResponse>({ url: '/ram/api/wechat/query-bind-config' })
}

export const checkCode = (data: { phone?: string; code?: string }) => {
  return http({ url: '/common/api/sms/check-phone-match-verification-code', data })
}

export const updateEmployeeName = (data: { employeeName: string }) => {
  return http({ url: '/ram/api/account/update-employee-name', data })
}

export const queryUserEmployeeInfo = () => {
  return http<QueryUserEmployee>({
    url: '/ram/api/user/query-user-employee-info',
  })
}

export const queryLoginInfo = () => {
  return http<ResponseData>({
    url: '/ram/api/user/query-login-info',
  })
}

export const settingUserInfo = (data: { username: string; password: string }) => {
  return http({
    url: '/ram/api/user/setting-username-password',
    data,
  })
}

export const queryInviteOrganization = (token: string) => {
  return http({
    url: `/ram/api/employee/query-invited-organization?invitedToken=${token}`,
  })
}

export const changeOrganization = (organizationId: number) => {
  return http<ResponseData>({
    url: '/ram/api/user/change-organization',
    data: {
      organizationId,
    },
  })
}

export const listOrganization = () => {
  return http<Organization[]>({
    url: '/ram/api/organization/list-organization-by-user-id',
  })
}

export function createOrganization(data: { organizationName: string }) {
  return http<Organization[]>({
    url: '/ram/api/organization/create-organization',
    data,
  })
}

export function queryOrganizationMemberByUserId(userId: number) {
  return http<MemberPermissionVO>({
    url: '/ram/api/organization-member/query-organization-member-by-user-id',
    data: { userId },
  })
}

export function updateOrganizationMember(data: MemberPermissionCommand) {
  return http({
    url: '/ram/api/organization-member/update-organization-member',
    data,
  })
}

export function listEmployeeRole(userId: number) {
  return http<Role[]>({
    url: '/ram/api/role/list-employee-role',
    data: { userId },
  })
}

export function updateEmployeeRole(data: UpdateStaffRoleListFormData) {
  return http({
    url: '/ram/api/role/update-employee-role',
    data,
  })
}

export function batchUpdateEmployeeRole(data: UpdateStaffRoleListFormData[]) {
  return Promise.all(data.map(d => updateEmployeeRole(d)))
}

export function updateEmployeeRole2(data: { userId: number; roleId: number }) {
  return updateEmployeeRole({
    userId: data.userId,
    employeeRoleList: [
      {
        roleId: data.roleId,
        roleName: '',
        delegate: false,
      },
    ],
  })
}

export function batchUpdateEmployeeRole2(data: { userId: number; roleId: number }[]) {
  return Promise.all(data.map(d => updateEmployeeRole2(d)))
}

export function listDepartmentEmployeeTree() {
  return http<DepartmentResponse>({
    url: '/ram/api/department/list-department-employee-tree',
    data: {},
  })
}

export function logout(data: { token: string }) {
  return http({
    url: '/ram/api/user/logout',
    data,
  })
}

export const queryCurrentOrganization = () => {
  return http<OrganizationVO>({ url: '/ram/api/organization/query-current-organization' })
}

export const updateOrganizationLogo = (data: { logo: string }) => {
  return http<OrganizationVO>({ url: '/ram/api/organization/update-organization-logo', data })
}

export const updateOrganizationName = (data: { name: string }) => {
  return http<OrganizationVO>({ url: '/ram/api/organization/update-organization-name', data })
}

export const transferOrganizationOwner = (data: { organizationId: number; userId: number }) => {
  return http<OrganizationVO>({ url: '/ram/api/organization/transfer-owner', data })
}

export const deleteOrganization = (data: { id: number }) => {
  return http<any>({ url: '/ram/api/organization/delete-organization', data })
}

export const listEmployeeByOrganizationId = (data: { organizationId: number }) => {
  return http<EmployeeVo[]>({
    url: '/ram/api/employee/list-employee-by-organization-id',
    data: { ...data, stateId: 'normal' },
  })
}
